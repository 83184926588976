import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['viaField', 'addViaButton'];
  static values = {viaVisible: Boolean};

  addVia() {
    // 経由地フィールドを表示
    this.viaFieldTarget.classList.remove('d-none');
    // 追加ボタンを非表示
    this.addViaButtonTarget.classList.add('d-none');
    this.viaVisibleValue = true;
    document.getElementById('via_visible_state').value = 'true';
  }

  removeVia() {
    // 経由地フィールドを非表示
    this.viaFieldTarget.classList.add('d-none');
    // 追加ボタンを再表示
    this.addViaButtonTarget.classList.remove('d-none');
    this.viaVisibleValue = false;
    document.getElementById('via_visible_state').value = 'false';
  }
}
