/* eslint-disable require-jsdoc */
import {Controller} from '@hotwired/stimulus';
import {removeUnitAndComma, formatNumber, normalizeCustomNumberValue, normalizeCustomDecimalValue} from 'javascripts/global/lib/form_tool';

export default class extends Controller {
  removeUnitAndCommaFromFormData(event) {
    const formData = event.detail.formSubmission.body;
    const form = event.detail.formSubmission.formElement;
    form.querySelectorAll('input[data-unit]').forEach((input) => {
      const changed = removeUnitAndComma(input.value, input.dataset.unit, input.dataset.unitPosition);
      formData.set(input.name, changed);
    });
  }

  removeUnitAndComma(event) {
    if (event.target === '') {
      return;
    }
    const unit = event.target.dataset.unit;
    const unitPosition = event.target.dataset.unitPosition;
    const newValue = removeUnitAndComma(event.target.value, unit, unitPosition);

    event.target.value = newValue;
  }

  normalizeCustomNumberValue(event) {
    if (event.target === '') {
      return;
    }

    event.target.value = normalizeCustomNumberValue(event.target.value);
  }

  normalizeCustomDecimalValue(event) {
    if (event.target === '') {
      return;
    }

    event.target.value = normalizeCustomDecimalValue(event.target.value);
  }

  format(event) {
    if (event.target === '') {
      return;
    }
    const isCommaSeparated = event.target.dataset.isCommaSeparated;
    const unit = event.target.dataset.unit;
    const unitPosition = event.target.dataset.unitPosition;
    const newValue = formatNumber(event.target.value, isCommaSeparated, unit, unitPosition);

    event.target.value = newValue;
  }
}
