import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['employeeCheckBoxGroup', 'allCheckedCheckBox', 'tableCheckActionGroup', 'tableCheckCount'];

  connect() {
  }

  // workflow action buttonをクリックしたときにモーダルのタイトルとボタンの値を変更する
  clickWorkflowAction(e) {
    document.querySelector('#commentFormModal').style = '';
    document.querySelector('#commentFormModal .modal-title').textContent = e.target.textContent;
    document.querySelector('#commentFormModal input[type="submit"]').value = e.target.textContent;

    // 手続きを強制的に完了させるの場合は、注意文言を表示する
    const noticeElement = document.querySelector('#commentFormModal .notice_force_complete');
    if (noticeElement) {
      noticeElement.remove();
    }
    document.querySelectorAll('#commentFormModal input[type="file"]').forEach((input) => {
      input.disabled = false;
    });

    if (['force-complete'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#commentFormModal input[type="submit"]').classList.add('btn-dark2');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-brand');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-danger');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = true;
      });
      const modalBody = document.querySelector('#commentFormModal .modal-body');
      const fragment = document.createRange().createContextualFragment(
          '<div class="notice_force_complete text-center text-danger">' +
        '<p><strong>※ この操作を行うと元に戻すことができません。よくご確認の上、操作してください。</strong></p></div>',
      );
      modalBody.parentNode.insertBefore(fragment, modalBody);
    } else if (['request'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#commentFormModal input[type="submit"]').classList.add('btn-brand');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-dark2');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-danger');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = true;
      });
      if (e.target.dataset.actionCommand === 'issue') {
        document.querySelectorAll('#commentFormModal input[type="file"]').forEach((input) => {
          input.disabled = true;
        });
      }
    } else if (['reflect'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#procedureCommitModal').classList.add('show');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = false;
      });
    } else if (['apply'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#commentFormModal input[type="submit"]').classList.add('btn-brand');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-dark2');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-danger');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = true;
      });
    } else if (['approval'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#commentFormModal input[type="submit"]').classList.add('btn-brand');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-dark2');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-danger');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = true;
      });
    } else if (['remanded'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#commentFormModal input[type="submit"]').classList.add('btn-danger');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-dark2');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-brand');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = true;
      });
    } else if (['reject'].some((status) => e.target.dataset.actionType === status)) {
      document.querySelector('#commentFormModal input[type="submit"]').classList.add('btn-dark2');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-brand');
      document.querySelector('#commentFormModal input[type="submit"]').classList.remove('btn-danger');
      document.querySelectorAll('#procedureCommitModal input').forEach((input) => {
        input.disabled = true;
      });
    }
  }

  toggleAllCheckBox(event) {
    const isChecked = event.target.checked;
    this.employeeCheckBoxGroupTargets.forEach((target) => {
      target.checked = isChecked;
    });

    this.#toggleDisplayActionButtons();
    this.#toggleDisplayCountOfSelectedEmployees();
    this.#updateSelectedIds();
  }

  toggleCheckBox(event) {
    const isAllChecked = this.employeeCheckBoxGroupTargets.every( (target) => target.checked);
    this.allCheckedCheckBoxTarget.checked = isAllChecked;

    this.#toggleDisplayCountOfSelectedEmployees();
    this.#toggleDisplayActionButtons();
    this.#updateSelectedIds();
  }

  // 選択済みアイテムの書類への入力状態を取得する
  // 取得した結果
  //   - 1つでも未入力があれば false
  //   - 全て入力済みであれば true
  // を返す
  #isEveryDocumentValuesExist() {
    let result = true;
    this.employeeCheckBoxGroupTargets.forEach((target) => {
      if (target.checked && target.dataset.documentValuesStatus === 'not-entered') {
        result = false;
        return true;
      }
    });

    return result;
  }

  #getWorkflowStatusList() {
    const statusList = new Set();
    this.employeeCheckBoxGroupTargets.forEach((target) => {
      if (target.checked) {
        statusList.add(target.dataset.workflowStatus);
      }
    });

    return Array.from(statusList);
  }

  // isProcessingのときは表示なし
  // inCharge Statusが１つのみではないときは表示なし
  // ２つ以上のステータス選択時は表示なし
  // １つのステータス選択時は表示あり（担当者かどうかで表示アクションが変わる）
  #toggleDisplayActionButtons() {
    const enableActionTypes = [];
    const statusList = this.#getWorkflowStatusList();
    const inChargeList = this.#checkedItemInChargeList();
    const workflowTypes = this.#checkedItemWorkFlowTypes();
    const canRejectList = this.#checkedItemCanRejectList();
    if (statusList.length > 1) {
      document.getElementById('multiple-status-selected-error-message').classList.remove('d-none');
    } else {
      document.getElementById('multiple-status-selected-error-message').classList.add('d-none');
    }

    if (statusList.length === 1 && !this.#isCheckedItemIncludingJobProcessing() && inChargeList.length === 1) {
      if (inChargeList[0] === 'true') {
        const targetStatus = statusList[0];

        switch (targetStatus) {
          case 'approval': // status = 承認待ち
            enableActionTypes.push('approval');
            enableActionTypes.push('remanded');
            enableActionTypes.push('force-complete');
            if (canRejectList.every((e) => e === 'true')) {
              enableActionTypes.push('reject');
            }
            break;
          case 'request': // status = 未対応
            if (this.#isEveryDocumentValuesExist()) {
              enableActionTypes.push('request');
            }

            enableActionTypes.push('reflect');
            enableActionTypes.push('force-complete');
            break;
          default:
            break;
        }
      } else if (inChargeList[0] === 'false') {
        // incharge=false && 従業員起点のときはapplyのみ追加
        const targetStatus = statusList[0];
        if (targetStatus === 'request' && workflowTypes.length === 1 && workflowTypes[0] === 'employee') {
          enableActionTypes.push('apply');
        }
        enableActionTypes.push('force-complete');
      }

      // enableActionTypesだけを表示して、それ以外を非表示にする
      const isCheckedExist = this.employeeCheckBoxGroupTargets.find( (target) => target.checked);
      if (isCheckedExist) {
        this.tableCheckActionGroupTargets.forEach((target) => {
          const isDisplayTarget = enableActionTypes.find((actionType) => {
            return target.dataset.actionType === actionType;
          });
          if (isDisplayTarget) {
            target.classList.remove('d-none');
          } else {
            target.classList.add('d-none');
          }
        });
      } else {
        this.tableCheckActionGroupTargets.forEach((target) => {
          if (targetStatus === 'approval') {
            target.classList.add('d-none');
          }
        });
      }
    } else {
      this.tableCheckActionGroupTargets.forEach((target) => {
        target.classList.add('d-none');
      });
    }
  }

  #toggleDisplayCountOfSelectedEmployees() {
    const checkCount = this.employeeCheckBoxGroupTargets.filter( (target) => target.checked).length;
    if (checkCount > 0) {
      this.tableCheckCountTarget.textContent = `${checkCount} 名の従業員を選択中`;
      this.tableCheckCountTarget.classList.remove('d-none');
    } else {
      this.tableCheckCountTarget.classList.add('d-none');
    }
  }

  #isCheckedItemIncludingJobProcessing() {
    return this.employeeCheckBoxGroupTargets.some((target) =>
      target.checked && target.dataset.isJobProcessing === 'true',
    );
  }

  /*
   * 選択された従業員の中で、担当者かどうかを取得する
   * stringifiedされたtrue/valueの２値のみを取得する
   */
  #checkedItemInChargeList() {
    const inChargeList = this.employeeCheckBoxGroupTargets
        .filter((target) => target.checked)
        .map((target) => target.dataset.isInCharge);

    return Array.from(new Set(inChargeList));
  }

  #checkedItemWorkFlowTypes() {
    const workflowTypeList = this.employeeCheckBoxGroupTargets.map((target) => target.dataset.workflowType);
    return Array.from(new Set(workflowTypeList));
  }

  #checkedItemCanRejectList() {
    const canRejectList = this.employeeCheckBoxGroupTargets
                              .filter((target) => target.checked)         // eslint-disable-line
                              .map((target) => target.dataset.canReject); // eslint-disable-line

    return Array.from(new Set(canRejectList));
  }

  #updateSelectedIds() {
    const selectedIds = this.employeeCheckBoxGroupTargets
        .filter( (target) => target.checked)
        .map( (target) => target.value);
    const inputElement = document.querySelector('input[type="hidden"][name="selected_item_ids"]');
    inputElement.setAttribute('value', selectedIds.join(','));
  }
}
