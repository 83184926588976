import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['button'];

  connect() {
    // FIXME: buttonTarget を使うようにしたい
    document.querySelector('#refresh').click();
  }
}
