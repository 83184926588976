import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['EmployeeCustomSelectId', 'EmployeeCustomSelectItems'];
  static values = {
    url: String,
  };

  loadEmployeeCustomSelectItems(event) {
    const id = this.EmployeeCustomSelectIdTarget.value;
    if (id) {
      fetch(`${this.urlValue}?employee_custom_select_id=${id}`, {headers: {'Accept': 'text/vnd.turbo-stream.html', 'Cache-Control': 'no-cache'}})
          .then((response) => response.text())
          .then((html) => Turbo.renderStreamMessage(html));
    } else {
      Turbo.renderStreamMessage('<turbo-stream action="replace" target="employee_custom_select_items_accordion">\n' +
        '<template><div id="employee_custom_select_items_accordion"></div></template>\n</turbo-stream>');
    }
  }
}
