import {Controller} from '@hotwired/stimulus';
import {get} from '@rails/request.js';

export default class extends Controller {
  static targets = ['geoJson', 'startCoordinates', 'goalCoordinates', 'viaCoordinates'];
  static values = {
    url: String,
    latitude: Number,
    longitude: Number,
    cid: String,
  };

  connect() {
    this.loadScript().then(() => {
      console.log('mapscript loaded!');
      this.initMap();
    });
  }

  async loadScript() {
    const response = await get(this.urlValue, {
      headers: {
        'Content-Type': 'application/javascript',
      },
    });

    if (response.ok) {
      const scriptContent = response.text;
      const script = document.createElement('script');
      script.textContent = scriptContent;
      // document.head.appendChild(script);
    } else {
      throw new Error(`Failed to load map script: ${response.statusCode}`);
    }
  }

  initMap() {
    const latitude = this.latitudeValue;
    const longitude = this.longitudeValue;

    const mapElement = this.element.querySelector('#route_car_map');
    this.mapTarget = new mapscript.Map(this.cidValue, {
      target: mapElement,
      center: new mapscript.value.LatLng(latitude, longitude),
      zoomLevel: 15,
    });
  }

  changeGeoJsonFigure() {
    // (1) GeoJson の値が変更されていない場合、何もしない
    if (this.previousGeoJson === this.geoJsonTarget.value) {
      return;
    }
    this.previousGeoJson = this.geoJsonTarget.value;

    // (2) 既存のルート線を削除
    if (this.figure) {
      this.mapTarget.removeGeoJsonFigure(this.figure);
    }

    // (3) 現在の GeoJson の値が存在しない場合、処理終了
    if (this.geoJsonTarget.value === '') {
      this.previousGeoJson = '';
      return;
    }

    // (4) 描画するGeoJsonを用意
    const geojson = JSON.parse(this.geoJsonTarget.value);

    // (5) ルート線オブジェクトを生成
    this.figure = new mapscript.value.GeoJsonFigureCondition(geojson, {
      isRouteShape: true, // ルート線かどうか(ルート描画の場合は必須)
    });

    // (6) 表示領域の調整
    // bbox から LatLngRect オブジェクトを生成
    const [lng1, lat1, lng2, lat2] = geojson.bbox;
    const rect = mapscript.util.locationsToLatLngRect([
      new mapscript.value.LatLng(lat1, lng1),
      new mapscript.value.LatLng(lat2, lng2),
    ]);

    // (7) LatLngRectが画面内に収まる位置に移動
    this.mapTarget.moveBasedOnLatLngRect(rect, true);

    // (8) ルート線を地図に追加
    this.mapTarget.addGeoJsonFigure(this.figure);
  }

  moveAndMarkStart() {
    // 念の為マーカーが残っていたら削除する
    if (this.startMarker) {
      this.mapTarget.removeMarker(this.startMarker);
    }

    const coordinates = this.startCoordinatesTarget.value;
    const [, latitude, longitude] = coordinates.split('--');
    this.mapTarget.setCenter(new mapscript.value.LatLng(latitude, longitude));

    this.startMarker = new mapscript.object.Marker({
      icon: 'https://static.navitime-api.navitime.biz/widget/calc_cost_car/start.png', // 使って良いのか...?
      position: new mapscript.value.LatLng(latitude, longitude),
    });

    this.mapTarget.addMarker(this.startMarker);
  }

  moveAndMarkGoal() {
    // 念の為マーカーが残っていたら削除する
    if (this.goalMarker) {
      this.mapTarget.removeMarker(this.goalMarker);
    }

    const coordinates = this.goalCoordinatesTarget.value;
    const [, latitude, longitude] = coordinates.split('--');
    this.mapTarget.setCenter(new mapscript.value.LatLng(latitude, longitude));

    this.goalMarker = new mapscript.object.Marker({
      icon: 'https://static.navitime-api.navitime.biz/widget/calc_cost_car/goal.png', // 使って良いのか...?
      position: new mapscript.value.LatLng(latitude, longitude),
    });

    this.mapTarget.addMarker(this.goalMarker);
  }

  moveAndMarkVia() {
    // 念の為マーカーが残っていたら削除する
    if (this.viaMarker) {
      this.mapTarget.removeMarker(this.viaMarker);
    }

    const coordinates = this.viaCoordinatesTarget.value;
    const [, latitude, longitude] = coordinates.split('--');
    this.mapTarget.setCenter(new mapscript.value.LatLng(latitude, longitude));

    this.viaMarker = new mapscript.object.Marker({
      icon: 'https://static.navitime-api.navitime.biz/widget/calc_cost_car/pin-1.png',
      position: new mapscript.value.LatLng(latitude, longitude),
    });

    this.mapTarget.addMarker(this.viaMarker);
  }

  removeStartMarker() {
    if (this.startMarker) {
      this.mapTarget.removeMarker(this.startMarker);
    }
  }

  removeGoalMarker() {
    if (this.goalMarker) {
      this.mapTarget.removeMarker(this.goalMarker);
    }
  }

  removeViaMarker() {
    if (this.viaMarker) {
      this.mapTarget.removeMarker(this.viaMarker);
    }
  }
}
