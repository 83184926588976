import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['disable'];

  connect() {
    document.addEventListener('turbo:after-stream-render', () => this.enable());
  }

  disable() {
    // commit パラメータが正しく送信されるように、disable にするまでのタイムアウトを設ける
    setTimeout(() => {
      this.disableTargets.forEach((target) => {
        if ('disabled' in target) {
          target.disabled = true;
        } else {
          target.classList.add('disabled');
        }

        const sendingText = target.dataset.elementDisableSendingText;
        if (sendingText) {
          target.value = sendingText;
        }
      });
    }, 13);
  }

  enable() {
    this.disableTargets.forEach((target) => {
      if ('disabled' in target) {
        target.disabled = false;
      } else {
        target.classList.remove('disabled');
      }
    });
  }
}
