// FIXME: Turboのアクションで書き換える
import {Controller} from '@hotwired/stimulus';

export default class extends Controller {
  static values = {
    uploadurl: String,
    fetchurl: String,
  };
  static targets = ['file', 'pictureId', 'message', 'width', 'height', 'fileType', 'fileSize', 'destroy'];

  destroy() {
    this.#removeFileInfo();
    this.#setErrorMessage('');
    this.fileTarget.value = '';
    const event = new CustomEvent('file-upload:end');
    window.dispatchEvent(event);
  }

  async upload() {
    try {
      const file = this.fileTarget.files[0];
      if (!file) return;
      if (!this.#validate_file(file)) return;
      const formData = new FormData();
      formData.append('file', file);
      const response = await fetch(this.uploadurlValue, {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        body: formData,
      });

      if (response.ok) {
        const data = await response.json();
        this.#setFileInfo(data);
        this.#setErrorMessage('');
      } else {
        const data = await response.json();
        this.#removeFileInfo();
        this.#setErrorMessage(data.message);
      }
      const event = new CustomEvent('file-upload:end');
      window.dispatchEvent(event);
    } catch (error) {
      this.#removeFileInfo();
      this.#setErrorMessage('');
      alert('通信エラーが発生しました。');
    }
  }

  #setErrorMessage(errorMessage) {
    this.messageTarget.value = errorMessage;
  }

  #setFileInfo(data) {
    this.pictureIdTarget.value = data.picture_id;
    this.fileTypeTarget.value = data.picture_file_type;
    this.widthTarget.value = data.picture_width;
    this.heightTarget.value = data.picture_height;
    this.fileSizeTarget.value = data.picture_file_size;
  }

  #removeFileInfo() {
    this.pictureIdTarget.value = '';
    this.fileTypeTarget.value = '';
    this.widthTarget.value = '';
    this.heightTarget.value = '';
    this.fileSizeTarget.value = '';
  }

  // jquery-validation.init.jsのvalidationを移植
  // 拡張子の偽装などはBGFileStoreの返り値で改めて検出する
  #validate_file(file) {
    const event = new CustomEvent('file-upload:end');

    const MAX_FILE_SIZE = 10 * 1024 * 1024; // 10MiB
    const MIN_FILE_SIZE = 1; // 1B

    // 以下に登場する文言はbg_file_storeのi18nに含める予定(英文を用意して合わせて追加する)
    if (file.size > MAX_FILE_SIZE || file.size < MIN_FILE_SIZE) {
      this.#removeFileInfo();
      this.#setErrorMessage('ファイルサイズが仕様外のため、アップロードに失敗しました。詳細はヘルプセンターをご参照ください。');
      window.dispatchEvent(event);
      return false;
    }

    const fileNameSplit = file.name.split('.');
    if (fileNameSplit.length < 2 || fileNameSplit.pop().toLowerCase() == 'heic') {
      this.#removeFileInfo();
      this.#setErrorMessage('ファイル形式が仕様外のため、アップロードに失敗しました。詳細はヘルプセンターをご参照ください。');
      window.dispatchEvent(event);
      return false;
    }

    return true;
  }
}
